@use "colors";

.photo_frame {
  position: relative;
  top: 0px;
  width: 100vw;
  height: 80vh;
  overflow: hidden;
  @media screen and (max-width: 1060px)
    {
height: 80vh;
    }

  > img {
    width: 100vw;
    height: 80vh;
    position: absolute;
    object-fit: cover;
    top: 0px;
    z-index: 1;
  }

  .photo_option_div {
    position: absolute;
    top: 0px;
    width: 100vw;
    height: 80%;
    z-index: 2;
    opacity: 0;
    transition: all 0.6s;

    &:hover {
      opacity: 1;
      transition: all 0.6s;
    }

    * {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.7;
    }

    img {
      transform: scale(35%);
    }

    .left_arrow {
      position: absolute;
      width: 250px;
      height: 100%;
      @media screen and (max-width: 1060px)
    {
      width:100px;
    }
      top: 0px;
      left: 0px;
      z-index: 2;
      transform: rotate(180deg);
      &:hover {
        cursor: pointer;
      }
    }

    .right_arrow {
      position: absolute;
      width: 250px;
      @media screen and (max-width: 1060px)
    {
      width: 100px;
    }
      top: 0px;
      height: 100%;
      right: 0px;
      z-index: 2;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .photo_description {
    position: absolute;
    bottom: 0px;
    height: 20%;
    min-height: 100px;
    width: 100%;
    z-index: 2;
    background-color: rgba(black, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: auto;
    @media screen and (max-width: 1060px)
    {
    display: flex;
    align-items: center;
    justify-content: center;
      min-height: 80px;
      bottom: 0px;
    }

    h1 {
      color: white;
      font-size: 48px;
      text-align: center;
      margin: 10px 0px 15px 0px;
      @media screen and (max-width: 1060px)
    {
      font-size: 32px;
    }
    }
    .circle {
      display: flex;

      column-gap: 10px;
      * {
        background: #d9d9d9;
        border: 1px solid white;
        width: 10px;
        height: 10px;
        border-radius: 100%;
      }
    }
  }
}

.product_options {
  background-color: colors.$whitest;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Inter';
  h1 {
    margin-top: 30px;
  }

  button {
    border: none;
    background-color: inherit;
  }

  .radio_options {
    opacity: 0;
    height: 0px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .image_options_div {
    position: relative;
    bottom: 2px;
    display: flex;
    justify-content: space-around;
    column-gap: 80px;
    row-gap: 80px;
    margin: 20px 20px 50px 20px;
    flex-wrap: wrap;
    @media screen and (max-width: 1260px)
    {
      column-gap: 40px;
      row-gap: 40px;
      margin: 10px 10px 30px 10px;
    }

    .category {
      flex-shrink: 1;
      position: relative;
      bottom: 0px;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      background-color: #FAFAFA;
      color: #FAFAFA;
      padding: 30px 30px 70px 30px;
      border-radius: 30px;
      box-shadow: 10px 10px 20px black;
    
      @media screen and (max-width: 1260px)
    {
      padding: 15px 15px 30px 15px;
      border-radius: 15px;
      box-shadow: 5px 5px 10px black;
    }
      &:hover{
        cursor: pointer;
      }
      > img {
        width: 250px;
        @media screen and (max-width: 1260px)
        {
          width: 120px;
        }
      }
      div {
        position: absolute;
        bottom: 30px;
        width: 60%;
        display: flex;
        background-color: rgba(colors.$orange, 0.6);
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        @media screen and (max-width: 1260px)
        {
          bottom: -10px;
          background-color: rgba(colors.$orange, 0.9);
          box-shadow: 5px 5px 8px black;
          text-align: center;
         
        }
        padding: 6px 6px 6px 6px;

        img {
          width: 40px;
          opacity: 0.6;
          @media screen and (max-width: 1260px)
          {
            display: none;
          }
        }
        p {
          margin: 10px 10px 10px 10px;
          @media screen and (max-width: 1260px)
          {
            margin: 4px 4px 4px 4px;
          }
        }
      }
    }
  }
  .checkbox_div {
    margin: 20px 20px 20px 20px;
    font-size: 24px;
    display: flex;
    align-items: center;
    font-family: 'Inter';
    input {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .orange_line{
    border: 2px solid colors.$orange;
    width: 60%;
    margin: 20px 20px 20px 20px;
  }
>p, >b{
  margin: 5px 5px 5px 5px;
}
.search_div{
  display: flex;
  align-items: center;
  input{
    margin: 30px 20px 30px 20px;
    background-color: #D9D9D9;
    width: 400px;
    height: 40px;
    border-radius: 25px;;
    padding-left: 8px;
    padding-right: 8px;
    @media screen and (max-width: 1060px)
    {
      width: 220px;
      margin-right: 0px;;
    }

  }
  img{
    &:hover {
      cursor: pointer;
    }
  }
}
}

.orange_div{
  background-color: colors.$orange;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-family: 'Inter';
}
.contact_info{
  background-color: colors.$gray;
  color: colors.$orange;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-bottom: 2px solid colors.$orange;
  font-family: 'Inter';
  b{
    margin: 10px 10px 10px 10px;
  }
  a{
    text-decoration: none;
    color: white;
    padding: 10px 10px 10px 10px;
    background-color: colors.$orange;
    border: 1px solid #9C5D14;
    @media screen and (max-width: 1060px)
    {
      margin-right: 10px;
    }
  }
}



.details{
  display: grid !important;
  grid-template-rows: 80px 50px 50px 1fr;
  grid-template-columns: 180px 180px 1fr ;
  row-gap: 10px;
  width: 80vh;
  justify-items: center;
  align-items: center;
  border: 1px solid #9C5D14;
  @media screen and (max-width: 500px)
  {
    display: flex !important;
    flex-direction: column;
  }
  >a{
    grid-row: 1/2;
    grid-column: 1/2;
        text-decoration: none;
        background-color: colors.$orange;
        padding: 5px 5px 5px 5px;
       
        color: white;
        font-weight: bolder;
        border-radius: 5px;
        box-shadow: 5px 5px 5px black;
        margin: 10px 10px 10px 10px;
      }
  .details-photo{
    grid-row: 4/5;
    grid-column: 1/3;
    
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    align-self: start;
    // justify-self: stretch;
  img{
  width: 360px;
    height: 200px;
    object-fit: contain;
  }}
  h2{
   
    grid-row: 2/3;
    grid-column: 1/4;
  }
  h3{
    grid-row: 3;
    grid-column: 1/4
  }
  p{

    grid-row: 4/5;
    grid-column: 3/4;
  }

  

}


// .details{
//   display: flex;
//   flex-direction: column;
//   >a{
//     text-decoration: none;
//     background-color: colors.$orange;
//     padding: 5px 5px 5px 5px;
//     align-self: flex-start;
//     color: white;
//     font-weight: bolder;
//     border-radius: 5px;
//     box-shadow: 5px 5px 5px black;
//     margin: 10px 10px 10px 10px;
//   }
//   .photo-carusel{
//     position: relative;
//     top: 0px;
//     display: flex;
//     align-items: stretch;
//     .arrow{
//       background-color: colors.$orange;
//       border-color: transparent;
//       color: white;
//       font-size: 30px;
//       font-weight: bolder;
//       opacity: 0.5;
//       cursor: pointer;

//     }

   

//   }
// }
// .photo-carusel{


// .photo{
//   position: relative;
//   top: 0px;
// display: flex;
// align-items: center;
// justify-content: center;
// transform-style: preserve-3d;
// perspective: 1000px;
// width: 1200px;
// height: 400px;
// @media  screen and (max-width: 1500px) {
// width:800px;
// }
// @media  screen and (max-width: 1100px) {
//   width:600px;
//   }
//   @media  screen and (max-width: 900px) {

// width:300px;
// height: 200px;
//   }
//   @media  screen and (max-width: 490px) {

//     width:150px;
//     height: 100px;
//   }
// *{
 
//   transform-style: preserve-3d;
// }
// .details-photo{
//   max-width: 400px;
//   max-height: 300px;
// border: 2px solid colors.$orange;   
// box-shadow: 20px 20px 20px black;
// @media  screen and (max-width: 490px) {
// padding: 15px 15px 15px 15px;
// }

// }
//  .main{
//   @media  screen and (max-width: 900px) {
//     width:200px;
//     height: 150px;
//     }
//     @media  screen and (max-width: 490px) {
//       width: 100px;
//       height: 80px;
//     }
//       position: relative;
//       top: 0px;
//       width: 400px;
//       height: 300px;
//       z-index: 3;
//       cursor: pointer;
//       background-color: colors.$whitest;
//    transition: all 1s;
//  }
//     .second-bef, .second-after{
//       cursor: pointer;
//       @media  screen and (max-width: 1500px) {
//         display: none;
//         }
//       position: relative;
//       top:-40px;
//       width: 300px;
//       height: 300px;
//       transform: scale(80%);
//       //transform: translateZ(50px);
//       z-index: 2;
//       transition: all 300ms;
      
//     }
//     .second-bef{
//       right:150px;
//     }

//     .second-after{
//       left:150px;
//     }

//     .third-bef, .third-after{
//       cursor: pointer;
//       @media  screen and (max-width: 1100px) {
//         display: none;
//       }
//       position: absolute;
//       top:-50px;
//       width: 400px;
//       height: 300px;
//      transform: scale(50%);
//       //transform: translateZ(1px);
//       z-index: 1;
//       transition: all 1s;
//       background-color: colors.$whitest;
//     }
//     .third-bef{
//       left:-60px;
//     }
//     .third-after{
//       right: -60px;
//     }
//     img{
//       height: 100%;
//       width: 100%;
//     }
// }
// }