@use 'colors';
@use 'fonts';

#main_tools{
    background-image: url('../../public/images/images/tools.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height:100vh;
    width: 100%;
    position:fixed;
    top:1;
    z-index: 1;
    div{
        height:100vh;
        width: 100vw;
        background-color: rgba(black,0.5);
    }
}
#main{
position: absolute;
top:0;
z-index:2;
width: 100vw;
}
#main_image {
    background: url('../../public/images/images/162162-30.jpg');
    background-size: cover;
    width: 100vw;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    div {

        background-color: rgba(colors.$white, 0.8);
        padding: 10px 25px 25px 25px;
        box-shadow: 15px 15px 20px black;

        h1 {
            text-align: center;
            -webkit-text-stroke: 1px black;
           
            @media screen and (min-width: 1061px)
            {
 font-size: 50px;
            }
            color: colors.$yellow;
            font-weight: bold;
            ;
            margin-bottom: 30px;
        }
        @media screen and (max-width: 1060px)
        {
            img{
                width: 200px;
            }
        }
    }
}

.moved_div {
    position: 'relative';
    top: -100px;
    display: flex;
    flex-direction: column;
    row-gap: 0;

    #image_links {
        background-color: colors.$gray;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 80px 20px 80px 20px;
        @media screen and (max-width: 1060px)
        {
            padding: 20px 10px 20px 10px;
            
        }
        border: 2px solid colors.$gray;
       
               
        .orange_line {
            border: 2px solid colors.$orange;
            height: 300px;
            @media screen and (max-width: 1060px)
            {
                height: 150px;
            }
        }
        >div{ perspective: 1000px;
            &:hover{
                cursor: pointer;
            }
        .image_link {
            &:hover {
                box-shadow: 15px 15px 25px colors.$orange;
                @media screen and (max-width: 1060px)
                {
                    box-shadow: 8px 8px 12px colors.$orange;
                }
                
                // transform: rotateY(360deg);
                //    transform-style: preserve-3d;
                transition: all 1.5s;
            }
            transform-style: preserve-3d;
            transition: all 1.5s;
            flex-shrink: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: fit-content;
            background-color: colors.$whitest;
            @media screen and (max-width: 1060px)
                {
                    padding: 10px 10px 10px 10px;
                    border-radius: 20px;
                    box-shadow: 15px 10px 26px;
                    width: 120px;
                    height: 120px;
                    box-shadow: 5px 2px 6px;
                }
            padding: 20px 20px 20px 20px;
            border-radius: 40px;
            box-shadow: 15px 10px 26px;
            width: 300px;
            height: 300px;

            b {
                @include fonts.orange_font;
                margin: 10px 10px 10px 10px;
                @media screen and (max-width: 1060px)
                {
                    font-size: 1.1em;
                }
            }

            .link_image {
                height: 200px;
                width: 200px;
                overflow: hidden;
                margin-top: 20px;
                @media screen and (max-width: 1060px)
                {
                    height: 60px;
                    width: 60px;
                    overflow: hidden;
                    margin-top: 10px;
                }

                >img {
                    width: 100%;

                }

                div {
                    transform: rotate(-35deg);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    bottom: 110px;
                    left: 0px;
                    background-color: colors.$whitest;
                    padding: 10px 30px 10px 30px;
                    border-radius: 20px;
                    width: fit-content;
                    border: 2px solid rgb(85, 85, 85);
                    @media screen and (max-width: 1060px)
                    {
                       display: none;
                    }

                    img {
                        width: 100%
                    }
                }
            }
        }}
    }
}

#icons_elements {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 50px 20px 50px;
    @media screen and (max-width: 1060px)
    {
        padding: 0px 0px 0px 0px;
        flex-direction: column;
        justify-content: start;
       
        
    }
    background-color: colors.$gray;
    overflow: visible;
    .icon_text {
        @media screen and (max-width: 1080px)
        {
            padding: 10px 10px 10px 20px;
            flex-basis: 40%;;
            margin: 10px 10px 10px 10px;
            column-gap: 20px;
        }
        visibility: hidden;
        display: flex;
        align-items: center;
        column-gap: 40px;
        padding: 20px 100px 20px 40px;
        flex-basis: 30%;;
        margin: 20px 20px 20px 20px;
        background-color: colors.$whitest;
border-left: 2px solid colors.$orange;
    border-bottom: 1px solid colors.$orange;
    border-top-right-radius: 25px;
    box-shadow: 6px 6px 25px;
        img {
            max-width: 100px;
        }

        b {
            font-size: 30px;
            text-align: center;
            @media screen and (max-width: 1060px)
            {
                font-size:20px;
            }
        }
    }
}
.relative_text{
    @include fonts.white_font;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 120px;
    padding: 20px 20px 20px 40px;
}