@use 'colors';
#contact_head{
    background-color: colors.$whitest;
    padding: 20px 10px 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2{
        font-family: 'inter';
    }
}

.contact_data{
    display: flex;
    background-color: colors.$gray;
    border-bottom: 1px solid colors.$orange;
    @media screen and (max-width: 1060px)
    {
        flex-direction: column;
    }
    >*{
        flex-basis: 50%;
    }
    >div:first-child{
        display: flex;
        flex-direction: column;
        align-items: space-around;
        padding: 10px 10px 10px 10px;
        img{
            width: 80px;
            
        }
        div:last-child{
            img{
                width: 70px;
                
            }
        }
        div{
            padding: 10px 10px 10px 10px;
            margin: 10px 10px 10px 10px;
            border: 1px solid colors.$orange;;
        }

        div, a{
            background-color: colors.$whitest;
            display: flex;
            
            column-gap: 30px;
            align-items: center;
        }
    }
    >div:last-child{
        .map_parent{
            padding: 20px 20px 20px 20px;
        .map{
            height: 600px;
        }}
    }
}